<template>
  <TitleBar :actions="actions">Call Logs</TitleBar>
  <div class="py-0 px-4">
    <div
      class="grid mt-3 surface-card shadow-2 p-3 border-1 border-50 border-round"
    >
      <DataTable
        class="col-12"
        :value="callLeadData"
        responsiveLayout="scroll"
        :lazy="true"
        :loading="tableDataIsLoading"
        :paginator="true"
        :rows-per-page-options="rowSelectionOptions"
        v-model:rows="rowsToShow"
        :totalRecords="totalRecords"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        paginator-template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        @page="onPage($event)"
        @sort="onSort($event)"
      >
        <template #header>
          <div class="justify-content-between h-2prem">
            <Button
              type="button"
              class="p-button-primary mx-2"
              @click="openFilters"
              icon="pi pi-filter"
              label="Filter and Search"
            />
            <ToggleButton
              v-model="uniqueOnly"
              onLabel="Showing Unique Calls"
              offLabel="Showing All Calls"
              onIcon="pi pi-filter"
              offIcon="pi pi-filter-slash"
            />

            <span style="float: right">
              <Button
                type="button"
                class="p-button-primary mx-2"
                @click="displayExport = !displayExport"
                icon="pi pi-download"
                label="Export"
              />
              <Button
                type="button"
                class="p-button-primary mx-2"
                @click="toggleOptional"
                icon="pi pi-eye"
                label="Toggle Columns"
                aria:haspopup="true"
                aria-controls="overlay_panel"
              />

              <OverlayPanel ref="optionalPanel" appendTo="body">
                <div
                  v-for="column in optionalColumns"
                  :key="column.field"
                  class="flex-1 px-2 py-2"
                >
                  <Checkbox
                    name="toggleField"
                    :value="column"
                    :binary="true"
                    v-model="column.toggle"
                  />
                  <label class="pl-2">{{ column.label }}</label>
                </div>
              </OverlayPanel>
            </span>
          </div>
        </template>

        <Column
          v-for="column in visibleColumns"
          :key="column.field"
          :field="column.field"
          :header="column.label"
          :sortable="column.sortable"
        ></Column>
      </DataTable>
    </div>

    <Dialog
      header="Filter Responders"
      v-model:visible="displayFilters"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <div class="px-2 py-1">
        <div class="surface-card p-4 shadow-2 border-round p-fluid">
          <div class="grid formgrid p-fluid">
            <div class="field mb-4 col-12">
              <label for="company_name" class="font-medium text-900"
                >Recent Mailings</label
              >
              <MultiSelect
                v-model="clonedFormFilters['mailing_id']"
                :options="filterOptions.recentMailings"
                optionLabel="formatted_description"
                placeholder="Select Mailings"
              />
              <p
                class="text-primary mt-2 text-xs"
                v-if="
                  typeof clonedFormFilters['mailing_id'] !== 'undefined' &&
                  clonedFormFilters['mailing_id'].length > 0
                "
                @click="clearFilters('mailing_id')"
              >
                Clear
              </p>
            </div>

            <div class="field mb-4 col-12 md:col-4">
              <label for="invoice_id" class="font-medium text-900"
                >Status</label
              >
              <MultiSelect
                v-model="clonedFormFilters['call_status']"
                :options="filterOptions.callStatus"
                placeholder="Select Status"
              />
              <p
                class="text-primary mt-2 text-xs"
                v-if="
                  typeof clonedFormFilters['call_status'] !== 'undefined' &&
                  clonedFormFilters['call_status'].length > 0
                "
                @click="clearFilters('call_status')"
              >
                Clear
              </p>
            </div>
            <div class="col-12 md:col-8"></div>
            <!-- <div class="field mb-4 col-12 md:col-4">
              <label for="invoice_id" class="font-medium text-900"
                >Device Type</label
              >
              <MultiSelect
                v-model="clonedFormFilters['device_type']"
                :options="filterOptions.deviceTypes"
                placeholder="Select Devices"
              />
              <p
                class="text-primary mt-2 text-xs mb-0"
                v-if="
                  typeof clonedFormFilters['device_type'] !== 'undefined' &&
                  clonedFormFilters['device_type'].length > 0
                "
                @click="clearFilters('device_type')"
              >
                Clear
              </p>
            </div> -->
            <!-- <div class="field mb-4 col-12 md:col-4">
              <label for="invoice_id" class="font-medium text-900"
                >Site Version</label
              >
              <MultiSelect
                v-model="clonedFormFilters['site_version']"
                :options="filterOptions.siteVersions"
                placeholder="Select Versions"
              />
              <p
                class="text-primary mt-2 text-xs mb-0"
                v-if="
                  typeof clonedFormFilters['site_version'] !== 'undefined' &&
                  clonedFormFilters['site_version'].length > 0
                "
                @click="clearFilters('site_version')"
              >
                Clear
              </p>
            </div> -->
            <div class="field mb-4 col-12 md:col-6">
              <label for="keyword" class="font-medium text-900">Keyword</label>
              <InputText v-model="clonedFormFilters['keyword']" />
              <p
                class="text-primary mt-2 text-xs"
                v-if="
                  typeof clonedFormFilters['keyword'] !== 'undefined' &&
                  clonedFormFilters['keyword'].length > 0
                "
                @click="clearFilters('keyword')"
              >
                Clear
              </p>
            </div>
            <div class="field mb-4 col-12 md:col-6">
              <label for="invoice_date" class="font-medium text-900"
                >Call Date Range</label
              >
              <Calendar
                :showIcon="true"
                selection-mode="range"
                v-model="clonedFormFilters['call_timestamp']"
                :manual-input="false"
                date-format="mm-dd-yy"
              ></Calendar>
            </div>
          </div>
        </div>
      </div>

      <template #footer>
        <Button
          label="Cancel"
          icon="pi pi-times"
          @click="closeFilters(false)"
          class="p-button-text"
        />
        <Button
          label="Apply Filters"
          icon="pi pi-check"
          @click="closeFilters(true)"
          autofocus
        />
      </template>
    </Dialog>

    <Dialog
      header="Export Responders"
      v-model:visible="displayExport"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <div class="grid mt-4">
        <div class="col-12 lg:col-8 lg:col-offset-2 text-center">
          <div v-if="displayExportMessage">
            <p>
              Continung will export all available columns for your filtered
              results.
            </p>

            <Button @click="exportResults">Export Now</Button>
          </div>
          <div v-else-if="exportIsLoading">
            <p>Please wait while we prepare your download.</p>
            <ProgressSpinner
              style="width: 50px; height: 50px"
              strokeWidth="2"
              animationDuration="2s"
            />
          </div>
          <div v-else>
            <p>Please click the button below to download your export.</p>
            <p>
              For security purposes, this link will only be valid for 10 minutes
            </p>
            <a :href="exportLink" target="_blank" style="text-decoration: none"
              ><Button
                @click="
                  displayExport = false;
                  exportIsLoading = false;
                  displayExportMessage = true;
                "
                >Download Now</Button
              ></a
            >
          </div>
        </div>
      </div>
      <template #footer>
        <Button
          label="Cancel"
          icon="pi pi-times"
          @click="displayExport = false"
          class="p-button-text"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import { ref, computed, onMounted, watch } from "vue";
import CallLead from "@/models/CallLead";
import QueryBuilderService from "@/service/QueryBuilderService";
import Calendar from "primevue/calendar";
import Campaign from "@/models/Campaign";
import Mailing from "@/models/Mailing";
import ToggleButton from "primevue/togglebutton";
//import { useRoute } from "vue-router";
import ProgressSpinner from "primevue/progressspinner";
import TitleBar from "@/components/TitleBar.vue";
import { useAppStore } from "@/stores/app";
export default {
  components: { Calendar, ProgressSpinner, TitleBar, ToggleButton },
  setup() {
    const appStore = useAppStore();
    //const route = useRoute();
    const rowSelectionOptions = [10, 20, 50, 100];
    const totalRecords = ref(0);
    const rowsToShow = ref(50);
    const callLeadData = ref([]);
    const actions = ref([]);
    const resultsPage = ref(1);
    const formFilters = ref([]);
    const filterOptions = ref({
      recentMailings: [],
      callStatus: ["ANSWER", "BUSY", "CANCEL", "CHANUNAVAIL", "CONGESTION"],
    });
    const clonedFormFilters = ref([]);
    const sortBy = ref("-id");
    const optionalPanel = ref();
    const displayFilters = ref(false);
    const displayExport = ref(false);
    const displayExportMessage = ref(true);
    const exportIsLoading = ref(false);
    const exportLink = ref("");

    const tableDataIsLoading = ref(false);
    //const hasFilters = ref(false);

    const uniqueOnly = ref(false);
    watch(uniqueOnly, () => {
      formFilters.value["uniqueOnly"] = uniqueOnly.value;
      loadData();
    });

    const allColumns = ref([]);

    const visibleColumns = computed(() =>
      allColumns.value.filter((item) => item.toggle)
    );

    const optionalColumns = computed(() =>
      allColumns.value.filter((item) => "toggle" in item)
    );

    const toggleOptional = (event) => {
      optionalPanel.value.toggle(event);
    };

    const openFilters = () => {
      console.log(formFilters.value);
      clonedFormFilters.value = { ...formFilters.value };
      console.log("ClonedFilters");
      console.log(clonedFormFilters.value);
      displayFilters.value = true;
    };
    const closeFilters = (shouldUpdate) => {
      if (shouldUpdate) {
        formFilters.value = { ...clonedFormFilters.value };
        loadData();
      }
      displayFilters.value = false;
    };

    const clearFilters = (filter) => {
      if (filter == "all") {
        //Reset All Filters
      } else {
        if (Array.isArray(clonedFormFilters.value[filter])) {
          clonedFormFilters.value[filter] = [];
        } else {
          clonedFormFilters.value[filter] = "";
        }
      }
    };

    const loadPrimaryFilters = async () => {
      //make a call
      await Mailing.where("campaign_id", appStore.currentCampaign.id)
        .get()
        .then((res) => {
          filterOptions.value.recentMailings = res.data;
        });

      //   await CallLead.custom("/form-leads/get-primary-filters")
      //     .where("campaign_id", store.getters["app/currentCampaign"].id)
      //     .first()
      //     .then((data) => {
      //       console.log(data);
      //       filterOptions.value.initialResponseChannels =
      //         data.initial_response_channel;
      //       filterOptions.value.deviceTypes = data.device_type;
      //       filterOptions.value.siteVersions = data.site_version;
      //     });
    };

    const initializeFilters = async () => {
      //   formFilters.value["mailing.campaign_id"] =
      //     store.getters["app/currentCampaign"].id;
      //   if (route.params.selected_mailing_id) {
      //     console.log("set mailingID");
      //     console.log(route.params.selected_mailing_id);
      //     let mailing = filterOptions.value["recentMailings"].find(
      //       (i) => i.id == route.params.selected_mailing_id
      //     );
      //     formFilters.value["mailing_id"] = [mailing];
      //   }
    };
    const loadData = async () => {
      tableDataIsLoading.value = true;
      let qb = new QueryBuilderService(CallLead, {
        filters: formFilters.value,
        limit: rowsToShow.value,
        sort: sortBy.value,
        page: resultsPage.value,
      });

      try {
        let response = await qb
          .buildQuery()
          .include("responder")
          .where("campaign_id", appStore.currentCampaign.id)
          .get();
        callLeadData.value = response.data;
        totalRecords.value = response.meta.total;
      } catch (err) {
        console.log("ERROR");
        console.log(err);
      }
      tableDataIsLoading.value = false;
    };

    const onPage = (event) => {
      resultsPage.value = event.page + 1;
      loadData();
    };
    const onSort = (event) => {
      if ("sortOrder" in event) {
        if (event.sortOrder === -1) {
          sortBy.value = "-" + event.sortField;
        } else {
          sortBy.value = event.sortField;
        }
        //Change page back to 1 when changing sort
        resultsPage.value = 0;
        loadData();
      }
    };

    const exportResults = async () => {
      displayExport.value = true;
      displayExportMessage.value = false;
      exportIsLoading.value = true;
      let qb = new QueryBuilderService(CallLead, {
        filters: formFilters.value,
        limit: rowsToShow.value,
        sort: sortBy.value,
        page: resultsPage.value,
      });

      await qb
        .buildQuery()
        .include("responder")
        .where("campaign_id", appStore.currentCampaign.id)
        .params({ export: "csv" })
        .get()
        .then((res) => {
          console.log(res.data[0].url);
          exportLink.value = res.data[0].url;
          exportIsLoading.value = false;
        });
    };

    watch(rowsToShow, () => {
      loadData();
    });

    const columnCallback = (fields, c) => {
      let fullFieldName = !c.field.includes(".")
        ? "call_lead." + c.field
        : c.field;
      let labelName = fullFieldName.includes("responder.")
        ? c.label
        : fullFieldName.charAt(0) + "." + c.label;
      let toggleValue = fields.includes(fullFieldName) ? true : false;
      let sortableValue = true;
      return {
        field: c.field,
        toggle: toggleValue,
        label: labelName,
        sortable: sortableValue,
      };
    };

    onMounted(async () => {
      await Campaign.find(appStore.currentCampaign.id).then((res) => {
        let visibleFields = JSON.parse(res.data.call_lead_visible);
        let responderColumns = JSON.parse(res.data.responder_field_map).map(
          (x) => {
            return {
              label: x.label,
              field: "responder." + x.field,
            };
          }
        );
        let formColumns = JSON.parse(res.data.call_field_map);
        allColumns.value = formColumns
          .concat(responderColumns)
          .map(columnCallback.bind(null, visibleFields));
      });

      await loadPrimaryFilters();
      await initializeFilters();

      await loadData();
    });

    return {
      tableDataIsLoading,
      rowsToShow,
      rowSelectionOptions,
      visibleColumns,
      optionalColumns,
      optionalPanel,
      displayFilters,
      filterOptions,
      clonedFormFilters,
      openFilters,
      closeFilters,
      clearFilters,
      uniqueOnly,
      toggleOptional,
      totalRecords,
      callLeadData,
      onPage,
      onSort,
      displayExport,
      displayExportMessage,
      exportResults,
      exportIsLoading,
      actions,
      exportLink,
    };
  },
};
</script>
<style>
@keyframes p-progress-spinner-color {
  100%,
  0% {
    stroke: --var(color-primary);
  }
  40% {
    stroke: --var(color-primary);
  }
  66% {
    stroke: --var(color-primary);
  }
  80%,
  90% {
    stroke: --var(color-primary);
  }
}
</style>
